.pageWrapper {
    padding: 60px 80px;
}

.profileTop {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
}
.profileBottom {
    padding: 24px 0;
    margin-top: 16px;
}
.avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: var(--surface-secondary);
    color: var(--text-main);
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
}
.profileBlock {
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 16px;
}
.profileBlockLink {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 16px;
    align-items: center;
    cursor: pointer;
}
.profileBlockLink div {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.profileBlockLink div svg {
    margin-right: 16px;
}
@media screen and (max-width: 1228px) {
    .pageWrapper {
        padding: 60px 40px;
    }
}
@media screen and (max-width: 744px) {
    .pageWrapper {
        padding: 60px 24px;
    }
}

@media screen and (max-width: 550px) {
    .pageWrapper {
        padding: 36px 24px 60px 24px;
    }
}
