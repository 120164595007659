.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--surface-main);
    box-shadow: rgb(0 0 0 / 16%) 0 0 6px;
}

.headerContainer {
    max-width: 1120px;
    margin: 0 auto;
    position: relative;
    padding: var(--default-indent);
    min-height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.contactUs {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
}

@media screen and (max-width: 768px) {
    .headerContainer {
        justify-content: center;
    }
    .contactUs {
        display: none;
    }
}