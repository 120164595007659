:root {
    --primary-default: #8F7AFF;
    --primary-hovered: #8571ed;
    --primary-active: #8F7AFF;
    --primary-disabled: #C3B8FF;
    --secondary-default: #FF964F;
    --secondary-hovered: #FFA466;
    --secondary-active: #F9802F;
    --secondary-disabled: #BDC3C7;
    --surface-main: #FFFFFF;
    --surface-secondary: #F6F6F8;
    --surface-default: #F3F5F7;
    --surface-hovered: #8F7AFF25;
    --text-main: #161616;
    --text-secondary: #383838;
    --text-secondary-subdued: #7D8896;
    --text-on-primary: #FFFFFF;
    --border-default: #D4DAE0;
    --border-subdued: #F3F5F7;
    --critical-default: #E74C3C;
    --critical-highlight: #FFC2CA;
    --critical-surface: #FEF5F5;
    --warning-default: #FEE14B;
    --warning-highlight: #FFF9E9;
    --warning-surface: #FFF9E9;
    --success-default: #49C95F;
    --success-highlight: #B0E8BA;
    --success-surface: #F2FCF4;
    --decorative-a: #E74C3C;
    --decorative-b: #FF964F;
    --decorative-c: #FFFFFF;
    --decorative-d: #3FAE52;
    --decorative-e: #EBEFF3;
    --decorative-f: #E4F9E7;
    --safe: #154360;
    --safe-surface: #1B4F72;
    --discount: #7F8C8D;
    --rating: #FF964F;
    --black-color: #000000;
    --button-paypal-color: #FFC439;
    --logo-color-fast: #34495E;
    --brand-primary-female-fast: #E74C3C;
    --background-color-fast: #ECF0F1;
    --text-secondary-fast: #95A5A6;
    --experiment-purpleLight: #DCD6F7;
    --experiment-sandLight: #F8E6D1;
    --grey-ligt: #BDC3C7;
    --background-subdude: #F2F2F2;
    --border-subdued2: #ECF0F1;
    --decorative-a: #E74C3C;
    --decorative-b: #F39C12;
    --decorative-c: #FFFFFF;
    --decorative-d: #27AE60;
    --decorative-e: #EBEFF3;
    --decorative-f: #E4F9E7;
    --default-indent: 16px;
    --fixed-container-indent: 106px;
    --default-indent-double: calc(var(--default-indent) * 2);
    --header-height: 56px;
    --border-radius-small: 24px;
    --border-radius-medium: 24px;
}
