.list li {
    margin-bottom: 12px;
    padding-left: 28px;
    line-height: 24px;
    position: relative;
}
.list li:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 10px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--text-main);
}