.grid {
    display: grid;
    margin-top: 24px;
    grid-auto-flow: row dense;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 40px 24px;
}


@media screen and (max-width: 1228px) {
    .grid {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
@media screen and (max-width: 744px) {
    .grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
@media screen and (max-width: 550px) {
    .grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
