.pageWrapper {
    padding: 60px 80px;
}

@media screen and (max-width: 1228px) {
    .pageWrapper {
        padding: 60px 40px;
    }
}
@media screen and (max-width: 744px) {
    .pageWrapper {
        padding: 60px 24px;
    }
}

@media screen and (max-width: 550px) {
    .pageWrapper {
        padding: 60px 24px 60px 24px;
    }
}
