.imageWrapper {
    width: 100%;
    height: 300px;
    background-color: #ebebeb;
}
.imageWrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.wrapperTop {
    width: 100%;
    position: absolute;
    padding: 13px;
    display: flex;
    justify-content: space-between;
}
.wrapperBottom {
    padding-left: 24px;
    padding-right: 24px;
}
.wrapperTop div {
    display: flex;
    flex-direction: row;
}
.wrapperTop .topButton:not(:last-child) {
    margin-right: 12px;
}
.topButton {
    background-color: #fff;
    border: 1px solid #00000014;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    box-shadow: 0 0 0 1px transparent, 0 0 0 4px transparent, 0 2px 4px rgba(0,0,0,0.18);
    display: flex;
}
.topButton img {
    width: 17px;
}
.backButton:hover {
    background-color: rgba(0,0,0,0.18);
}

.info {
    padding-bottom: 32px;
    border-bottom: 1px solid #00000014;
}
.lessons {
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 40px 24px;
    padding-bottom: 32px;
}
.lesson {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.lessonImage {
    position: relative;
    width: 100%;
    background-color: #DDDDDD;
    border-radius: 12px;
    margin-bottom: 16px;
    background-position: 50% 50%;
    overflow: hidden;
}
.lessonImageImg {
    width: 100%;
    height: 100% !important;
    object-fit: cover !important;
    aspect-ratio: 20 / 19;
}
.lessonImageCover {
    position: absolute;
    background: #00000015;
    width: 100%;
    height: 100%;
    top: 0;
}
.progressWrapper {
    width: 100%;
    display: flex;
    align-items: center;
}
.progressBox {
    position: relative;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background-color: rgba(212 218 224 / 50%);
    margin: 12px 0;
}

.progressBar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 5px;
    background-color: var(--primary-default);
    transition: width 0.2s linear;
}
.progressStatus {
    min-width: 26px;
    height: 26px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
@media screen and (max-width: 1228px) {
    .lessons {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
@media screen and (max-width: 744px) {
    .lessons {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
@media screen and (max-width: 550px) {
    .lessons {
        grid-gap: 24px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

