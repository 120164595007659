.landingWrapper {
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.top,
.middle,
.bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 44px;
}

.middle {
    border: 1px solid var(--border-default);
    background-color: var(--surface-secondary);
    border-radius: var(--border-radius-medium);
    padding: 40px 60px;
}

.top .left, .middle .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.top .right {
    padding: 0 50px;
}
.left, .right {
    max-width: 560px;
}
.middle .left, .middle .right {
    max-width: 460px;
}
.middle .right {
    padding: 0 100px;
}
.bottom {
    margin-top: 120px;
}
img {
    width: 100%;
}
@media screen and (min-width: 1200px) {
    .landingWrapper button {
        width: 70%;
    }
}
@media screen and (max-width: 1200px) {
    .top, .bottom {
        text-align: center;
    }
    .top,
    .middle,
    .bottom {
        justify-content: center;
    }
    .middle {
        position: relative;
        padding: 16px 24px;
        margin-left: -40px;
        margin-right: -40px;
        border-radius: unset;
    }
    .top .right, .middle .right {
        padding: 0;
    }
    .bottom {
        margin-top: 16px;
    }
}
@media screen and (max-width: 768px) {
    .middle {
        margin-left: -24px;
        margin-right: -24px;
    }
}