.cardContainer {
    overflow: hidden;
    flex: 1 1 calc(33.333% - 20px);
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.cardImage {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #DDDDDD;
    border-radius: 12px;
    margin-bottom: 15px;
    background-position: 50% 50%;
    overflow: hidden;
    aspect-ratio: 20/19;
}

.cardImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.cardImageCover {
    position: absolute;
    background: #00000015;
    width: 100%;
    height: 100%;
    top: 0;
}

.cardFavouriteButton {
    position: absolute;
    top: 12px;
    right: 12px;
    background-color: #fff;
    border: 1px solid #00000014;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    box-shadow: 0 0 0 1px transparent, 0 0 0 4px transparent, 0 2px 4px rgba(0,0,0,0.18);
    display: flex;
    z-index: 10;
}
