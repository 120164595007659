html {
    font-family: DM Sans, sans-serif;
    font-size: 0;
    background-color: var(--surface-main);
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
html,
#root {
    min-height: 100%;
    height: 100%;
    position: relative;
    font-size: 16px;
}

#onboarding-page {
    height: 100%;
}

.ReactModal__Body--open {
    overflow: hidden;
}
