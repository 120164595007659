.wrapper {
    position: fixed;
    z-index: 10000;
    bottom: 0;
    left: 0;
    padding: var(--default-indent);
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: var(--surface-main);
    border-top: 1px solid var(--border-default);
    box-shadow: rgb(0 0 0 / 16%) 0 0 6px;
}
