.sections {
    display: flex;
    overflow: hidden;
    max-width: 560px;
    margin: 0 auto;
}

.section {
    width: 100%;
}

.blockImage {
    margin-bottom: 24px;
}
.rawText{
    font-family: 'DM Sans', sans-serif;
    color: #161616;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
}
.rawText ul li {
    margin-bottom: 12px;
    padding-left: 28px;
    line-height: 24px;
    position: relative;
}
.rawText ul li:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 10px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--text-main);
}

.rawText blockquote {
    margin-bottom: 24px;
    background-color: var(--surface-hovered);
    padding: 16px;
    border-left: 4px solid var(--primary-default);
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
}
@keyframes fadeIn {
    to {
        opacity: 1;
    }
}