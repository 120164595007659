.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--surface-main);
    box-shadow: rgb(0 0 0 / 16%) 0 0 6px;
}

.headerContainer {
    position: relative;
    padding: 15px var(--default-indent) 0;
    min-height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.top > div {
    display: flex;
    align-items: center;
}
.progressBox {
    position: relative;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background-color: rgba(212 218 224 / 50%);
}

.progressBar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 5px;
    background-color: var(--primary-default);
    transition: width 0.2s linear;
}

.iconBack, .iconClose {
    cursor: pointer;
    color: var(--text-secondary);
}
.iconBack {
    margin-right: 12px;
}
.iconClose {
    width: 24px;
    height: 24px;
    margin-left: 12px;
}