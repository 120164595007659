.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: var(--surface-main);
    box-shadow: rgb(0 0 0 / 16%) 0 0 6px;
}

.footerContainer {
    max-width: 1120px;
    margin: 0 auto;
    position: relative;
    min-height: 56px;
}
.links {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.links div {
    cursor: pointer;
    padding: 16px 24px;
}

@media screen and (max-width: 1120px) {
    .links div {
        text-align: center;
        width: 100%;
    }
}